<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content
      :padding="2"
    >
      <v-simple-table>
        <template #default>
          <tbody>
            <tr>
              <td>Versión</td>
              <td>{{ version }}</td>
            </tr>
            <tr>
              <td>Navegador</td>
              <td>{{ navigator }}</td>
            </tr>
            <tr>
              <td>Versión del navegador</td>
              <td>{{ navigatorVersion }}</td>
            </tr>
            <tr>
              <td>Resolución de pantalla</td>
              <td>{{ screenWidth }}x{{ screenHeight }}</td>
            </tr>
            <tr>
              <td>Sistema operativo</td>
              <td>{{ platform }}</td>
            </tr>
            <tr>
              <td>Standalone</td>
              <td>{{ standalone|humanizeBoolean }}</td>
            </tr>
            <tr>
              <td>Almacenamiento</td>
              <td>1 Tb</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { basePageMixin } from '@/mixins/basePageMixin'

export default {
  mixins: [basePageMixin],
  data () {
    return {
      version: __BETA10_VERSION__,
      standalone: false,
      navigator: '',
      navigatorVersion: '',
      screenWidth: 0,
      screenHeight: 0,
      platform: '',
      toolbarOptions: {
        administracionBD: {
          title: 'Administración de la base de datos',
          visible: true,
          icon: 'database',
        },
      },
    }
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Configuración'
      this.standalone = window.matchMedia('(display-mode: standalone)').matches
      this.navigator = navigator.appName
      this.navigatorVersion = navigator.appVersion
      this.screenWidth = window.screen.width
      this.screenHeight = window.screen.height
      this.codename = navigator.appCodeName
      this.platform = navigator.platform
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.administracionBD) {
        this.$appRouter.push({
          name: 'configuracion__admin-bd',
        })
      }
    },
  }
}
</script>
